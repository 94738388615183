import React from "react"
import Lottie from "react-lottie"
import * as animationData from "./IndexWindmill.json"

export default function IndexWindmill() {
  const defaultOptions = {
    loop: true,
    autoplay: true,

    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMinYMin slice",
    },
  }
  return <Lottie options={defaultOptions} />
}
